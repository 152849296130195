<template>
  <div class="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 lg:gap-6">
    <div
      v-for="restaurant in restaurantsToShow.slice(0, 8)"
      :key="
        createLoopId({
          name: restaurant.name,
          id: restaurant.id,
        })
      "
      class="w-full"
    >
      <RestaurantCard v-bind="restaurant" :is-loading="false" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toRefs, computed } from "vue";
import RestaurantCard from "~/components/restaurant/RestaurantCard/RestaurantCard.vue";
import type { RestaurantCardProps } from "~/types/Restaurant";
import { createLoopId } from "~/helpers/restaurant";

export interface Props {
  restaurants: RestaurantCardProps[];
  slidePerView: number | string;
}

const props = defineProps<Props>();
const { restaurants } = toRefs(props);
const restaurantsToShow = computed(() => {
  return restaurants.value;
});
</script>
